import { createContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

export const AuthContext = createContext();

export default ({ children }) => {
  const navigate = useNavigate();
  const [isAuthenticate, setIsAuthenticate] = useState(false);
  const [initialLogin, setInitialLogin] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [userInfos, setUserInfos] = useState(null);
  const [token, setToken] = useState(localStorage.getItem("Token") || "");
  const [teamId, setTeamId] = useState(
    localStorage.getItem("selectedTeamId") || ""
  );
  const [teamDetails, setTeamDetails] = useState(null);

  // const [keyword, setKeyword] = useState(
  //   localStorage.getItem("selectedKeyword") || ""
  // );
  const [keyword, setKeyword] = useState("");
  // const [keyword, setKeyword] = useState(JSON.parse(localStorage.getItem("selectedKeyword") || "{}"));
  const [isFromExtension, setIsFromExtension] = useState(false);

  // Initialisation des selectedSources à partir de localStorage
  const [selectedSources, setSelectedSources] = useState(() => {
    const savedSources = localStorage.getItem("selectedSources");
    return savedSources
      ? JSON.parse(savedSources)
      : [
          "linkedin",
          "twitter",
          "reddit",
          "googleNews",
          "facebook",
          "internet",
          "youtube",
          "pinterest",
          "medium",
          "tiktok",
          "indiehacker",
          "everywhere",
        ];
  });

  // Utilisation de useEffect pour persister les selectedSources dans le localStorage
  useEffect(() => {
    localStorage.setItem("selectedSources", JSON.stringify(selectedSources));
  }, [selectedSources]);

  const disconnect = () => {
    setToken("");
    setIsAuthenticate(false);
    setUserInfos(null);
    setTeamId(null);
    setInitialLogin(true);
    setTeamDetails(null);
    setKeyword(null);
    setIsFromExtension(false);
    localStorage.removeItem("selectedKeyword");
    localStorage.removeItem("selectedSources");
    navigate("/");
  };

  useEffect(() => {
    setIsLoading(true);
    localStorage.setItem("Token", token);
    const headers = {};

    if (token) {
      headers.Authorization = `Bearer ${token}`;
      fetch(process.env.REACT_APP_BASEURL + "/api/user/profile", {
        headers: new Headers(headers),
        method: "GET",
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.success) {
            setIsAuthenticate(true);
            setUserInfos({
              id: res.user._id,
              email: res.user.email,
              image: res.user.image,
              onboarding: res.user.onboarding,
              firstname: res.user.firstname,
              surname: res.user.surname,
              referal: res.user.affiliateCode,
              isPremium: res.user.isPremium,
              premiumPlan: res.user.premiumPlan,
              linkedinCookieActivate: res.user.linkedinCookieActivate,
            });

            // Récupérer les équipes de l'utilisateur
            fetch(
              process.env.REACT_APP_BASEURL + `/api/teams/user/${res.user._id}`,
              {
                headers: new Headers(headers),
                method: "GET",
              }
            )
              .then((teamRes) => teamRes.json())
              .then((teamData) => {
                if (teamData.success && teamData.teams.length > 0) {
                  const defaultTeam = teamData.teams[0];
                  const defaultTeamId = teamData.teams[0]._id;
                  setTeamId(defaultTeamId);
                  setTeamDetails(defaultTeam);

                  // Vérifier si les mots-clés existent et sont un tableau non vide
                  if (defaultTeam.keyword && defaultTeam.keyword.length > 0) {
                    setKeyword(defaultTeam.keyword[0].keywordName);
                  } else {
                    setKeyword(""); // Optionnel: définir un mot-clé par défaut ou laisser vide
                  }
                }
                setIsLoading(false); // Déplacer ici pour s'assurer que le chargement se termine après la récupération de l'équipe
              })
              .catch((err) => {
                console.error("Error fetching teams:", err);
                setIsLoading(false); // S'assurer que le chargement est terminé même en cas d'erreur
              });
          } else {
            console.error("Error during authentication:", res);
            setIsLoading(false);
          }
        })
        .catch((err) => {
          console.error("Network error:", err);
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
    }
  }, [token]);

  const isFreeTrialOver = () => {
    if (teamDetails && teamDetails.endFreeTrial) {
      const endFreeTrialDate = new Date(teamDetails.endFreeTrial);
      return new Date() > endFreeTrialDate;
    }
    return false;
  };

  const isFreeTrial = () => {
    if (teamDetails && teamDetails.endFreeTrial) {
      const endFreeTrialDate = new Date(teamDetails.endFreeTrial);
      return new Date() > endFreeTrialDate;
    }
    return false;
  };

  const getDaysRemainingForTrial = () => {
    if (teamDetails && teamDetails.endFreeTrial) {
      const endFreeTrialDate = new Date(teamDetails.endFreeTrial);
      const today = new Date();

      // Réglage de l'heure à minuit pour les deux dates
      endFreeTrialDate.setHours(0, 0, 0, 0);
      today.setHours(0, 0, 0, 0);

      const timeDiff = endFreeTrialDate.getTime() - today.getTime();
      return Math.max(Math.ceil(timeDiff / (1000 * 3600 * 24)), 0);
    }
    return 0;
  };

  const refreshTeamInfo = async () => {
    const headers = {};
    if (token) {
      headers.Authorization = `Bearer ${token}`;

      try {
        const response = await fetch(
          process.env.REACT_APP_BASEURL + `/api/teams/user/${userInfos.id}`,
          {
            headers: new Headers(headers),
            method: "GET",
          }
        );
        const teamData = await response.json();

        if (teamData.success && teamData.teams.length > 0) {
          const defaultTeam = teamData.teams[0];
          const defaultTeamId = defaultTeam._id;

          setTeamId(defaultTeamId);
          setTeamDetails(defaultTeam);

          if (defaultTeam.keyword && defaultTeam.keyword.length > 0) {
            setKeyword(defaultTeam.keyword[0].keywordName);
          } else {
            setKeyword("");
          }
        }
      } catch (error) {
        console.error("Error fetching updated team details:", error);
      }
    }
  };

  useEffect(() => {
    localStorage.setItem("selectedTeamId", teamId);
  }, [teamId]);

  useEffect(() => {
    localStorage.setItem("selectedKeyword", keyword);
  }, [keyword]);

  useEffect(() => {
    if (localStorage.getItem("Token")) {
      setToken(localStorage.getItem("Token"));
    }
  }, []);

  return (
    <AuthContext.Provider
      value={{
        token,
        setToken,
        userInfos,
        setUserInfos,
        disconnect,
        isAuthenticate,
        setIsAuthenticate,
        teamId,
        setTeamId,
        teamDetails,
        setTeamDetails,
        isLoading,
        setIsLoading,
        keyword,
        setKeyword,
        selectedSources,
        setSelectedSources,
        isFreeTrialOver: isFreeTrialOver(),
        isFreeTrial,
        daysRemainingForTrial: getDaysRemainingForTrial(),
        isFromExtension,
        setIsFromExtension,
        refreshTeamInfo,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
